import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { Box, TextField, Button, Typography } from "@mui/material";
import { getSession } from "../session";
import BottomNav from "./BottomNav";
import Header3 from "./Header3";

function ChatBox() {
  const { projectId, proposalId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [authUser, setAuthUser] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchAuthUserAndMessages = async () => {
      try {
        const user = await getSession();
        if (!user) {
          console.error("No authenticated user found.");
          // Opcional: redirigir al usuario a la página de inicio de sesión
          return;
        }

        const userDocRef = doc(db, "users", user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          setAuthUser({
            uid: user.uid,
            // 2. Use displayName from userData
            displayName: userData.displayName || user.email,
            email: user.email,
          });

          if (!projectId || !proposalId) {
            console.error("Project ID or Proposal ID is missing");
            return;
          }
        } else {
          console.error("User document not found in Firestore");
          // Handle the case where user data is not found
        }

        const messagesPath = `projects/${projectId}/proposals/${proposalId}/messages`;
        const messagesRef = collection(db, messagesPath);
        const q = query(messagesRef, orderBy("timestamp"));

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const newMessages = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMessages(newMessages);
          scrollToBottom();
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error in fetchAuthUserAndMessages:", error);
      }
    };

    fetchAuthUserAndMessages();
  }, [projectId, proposalId]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = async () => {
    console.log("handleSendMessage called");

    if (newMessage.trim() === "") {
      console.error("Message is empty");
      // Opcional: Mostrar un mensaje de error al usuario
      return;
    }

    if (!authUser) {
      console.error("No authenticated user");
      // Opcional: Redirigir al usuario a la página de inicio de sesión
      return;
    }

    if (!projectId || !proposalId) {
      console.error("Missing projectId or proposalId", {
        projectId,
        proposalId,
      });
      // Opcional: Mostrar un mensaje de error al usuario
      return;
    }

    const message = {
      content: newMessage.trim(),
      timestamp: new Date(),
      senderId: authUser.uid,
      senderDisplayName: authUser.displayName,
      read: false,
    };

    const messagesPath = `projects/${projectId}/proposals/${proposalId}/messages`;
    console.log("Sending message to path:", messagesPath);

    try {
      const messagesRef = collection(db, messagesPath);
      const docRef = await addDoc(messagesRef, message);
      console.log("Message sent successfully with ID:", docRef.id);
      setNewMessage("");
      scrollToBottom();
    } catch (error) {
      console.error("Error sending message: ", error);
      // Opcional: Mostrar un mensaje de error al usuario
    }
  };

  const markMessageAsRead = async (messageId) => {
    if (!projectId || !proposalId) return;

    const messageRef = doc(
      db,
      `projects/${projectId}/proposals/${proposalId}/messages`,
      messageId
    );
    try {
      await updateDoc(messageRef, { read: true });
    } catch (error) {
      console.error("Error marking message as read: ", error);
    }
  };

  useEffect(() => {
    if (authUser) {
      messages.forEach((message) => {
        if (message.senderId !== authUser.uid && !message.read) {
          markMessageAsRead(message.id);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, authUser, projectId, proposalId]);

  return (
    <Box sx={{ backgroundColor: "slateblue" }}>
      <Header3 />
      {!projectId ? (
        <Typography variant="h5" gutterBottom color="error">
          Product ID is undefined
        </Typography>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            Chat sobre el producto
          </Typography>
          <Box
            style={{
              height: "calc(90vh - 184px)",
              overflowY: "scroll",
              marginTop: "50px",
              paddingBottom: "150px",
              backgroundColor: "slateblue",
            }}
          >
            {messages.map((message) => (
              <div
                key={message.id}
                style={{
                  textAlign: message.senderId === authUser.uid ? "right" : "left",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    bgcolor: message.senderId === authUser.uid ? "#CAEDED" : "#FFF8E1",
                    m: 3,
                    p: 2,
                    borderRadius: "10px",
                    display: "inline-block",
                    color: "black",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      color:
                        message.senderId === authUser.uid
                          ? "dodgerblue"
                          : "mediumseagreen",
                    }}
                  >
                    {message.senderDisplayName}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    {message.content}
                  </Typography>
                </Typography>

                {message.senderId === authUser.uid && (
                  <Typography
                    sx={{
                      display: "block",
                      padding: "0px 20px 0px 20px",
                      color: "white",
                    }}
                    variant="caption"
                    color="textSecondary"
                  >
                    {message.read ? "Leído" : "Enviado"}
                  </Typography>
                  
                )}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            style={{
              width: "100%",
              position: "fixed",
              bottom: 65,
              background: "#fff",
              padding: "8px 0",
            }}
          >
            <TextField
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Escribe tu mensaje..."
              variant="outlined"
              fullWidth
              style={{
                width: "70%",
                margin: "auto",
                marginRight: "10px",
              }}
            />
            <Button
              style={{
                width: "20%",
                height: "56px",
                margin: "auto",
              }}
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
            >
              Enviar
            </Button>
          </Box>
          <BottomNav />
        </>
      )}
    </Box>
  );
}

export default ChatBox;
