import React from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationIcon from "./NotificationIcon";
import ChatIcon from '@mui/icons-material/Chat';


const BottomToolbar = () => {
    const classes = {
        appBar: {
            top: "auto",
            bottom: 0,
            backgroundColor: "white",
            color: "black",
            borderTop: "1.5px solid black"
        },
        toolbar: {
            justifyContent: "space-around",
        },
    };
    return (
        <AppBar position="fixed" sx={classes.appBar}>
            <Toolbar sx={classes.toolbar}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <IconButton href="/home" color="black">
                        <HomeIcon />
                    </IconButton>
                    <Typography variant="caption" fontSize={9} align="center">
                        Inicio
                    </Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <IconButton href="/chats" color="black">
                        <ChatIcon />
                    </IconButton>
                    <Typography variant="caption" fontSize={9} align="center">
                        Chats
                    </Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <IconButton href="/notifications" color="black">
                        <NotificationIcon />
                    </IconButton>
                    <Typography variant="caption" fontSize={9} align="center">
                        Notificaciones
                    </Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <IconButton href="/profile" color="black">
                        <AccountCircleIcon />
                    </IconButton>
                    <Typography variant="caption" fontSize={9} align="center">
                        Perfíl
                    </Typography>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default BottomToolbar;
