/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Box,
  Typography,
  List,
  ListItem,
  CircularProgress,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db, getToken } from "../firebase";
import {
  onSnapshot,
  collection,
  getDocs,
  where,
  query,
  updateDoc,
  doc,
  getDoc,
  or,
  and,
} from "firebase/firestore";
import BottomNav from "./BottomNav";
import Atras from "./atras";

const Notifications = () => {
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState(null);
  const [proposals, setProposals] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [userType, setUserType] = useState(""); // Estado para almacenar el tipo de usuario

  useEffect(() => {
    const fetchProposals = async () => {
      if (!isLoggedIn()) {
        navigate("/login");
        return;
      }

      try {
        const token = await getToken();
        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }
        const { uid } = currentUser;
        setUid(uid);

        // Obtener el tipo de usuario desde la colección de usuarios
        const userDoc = await getDoc(doc(db, "users", uid));
        if (!userDoc.exists()) {
          console.error("No se encontró información de usuario.");
          return;
        }

        const userData = userDoc.data();
        setUserType(userData.userType);

        setLoading(true);

        // Leer las notificaciones no leídas y actualizarlas a leídas
        const notificationsSnapshot = await getDocs(
          query(
            collection(db, "notifications"),
            where("userId", "==", uid),
            where("read", "==", false)
          )
        );

        const notificationUpdatePromises = notificationsSnapshot.docs.map(
          (doc) => updateDoc(doc.ref, { read: true })
        );

        await Promise.all(notificationUpdatePromises);
        console.log("Notificaciones actualizadas a leídas.");

        const projectsSnapshot = await getDocs(collection(db, "proyects"));
        const projectsData = projectsSnapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          timeToDo: doc.data().timeToDo,
          uid: doc.data().uid,
        }));

        const allProposals = [];
        for (const project of projectsData) {
          const proposalsSnapshot = await getDocs(
            collection(db, "proyects", project.id, "proposals")
          );
          const projectProposals = proposalsSnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              projectId: project.id,
              projectTitle: project.title,
              projectUid: project.uid,
              timeToDo: project.timeToDo ? project.timeToDo.toDate() : null,
              sentAt: data.timestamp ? data.timestamp.toDate() : null,
              uid: data.uid,
            };
          });
          allProposals.push(...projectProposals);
        }

        setProposals(allProposals);
        setLoading(false);
        console.log("Propuestas obtenidas:", allProposals);
      } catch (error) {
        console.error("Error al obtener propuestas:", error);
        setLoading(false);
      }
    };

    fetchProposals();
  }, [navigate]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleDateString();
    }
    return "N/A";
  };

  const filteredProposals = proposals.filter((proposal) =>
    proposal.projectTitle
      ? proposal.projectTitle.toLowerCase().includes(searchTerm.toLowerCase())
      : false
  );

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    listItem: {
      display: "block",
      width: "95%",
      border: "0.5px solid #ccc",
      margin: "15px auto",
      boxShadow: "0px 0px 3px 0px black",
    },
    contFilt: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      marginTop: "140px",
    },
    inputS: {
      width: "64%",
      height: "35px",
      marginTop: "10px",
      padding: "5px",
      fontSize: "14px",
      border: "3px solid grey",
      borderRadius: "4px",
    },
    drop: {
      width: "auto",
      height: "49px",
      marginTop: "10px",
      marginLeft: "10px",
      padding: "5px",
      fontSize: "14px",
      border: "3px solid grey",
      borderRadius: "4px",
    },
    name: {
      color: "#312783",
      fontSize: "28px",
      fontWeight: "bold",
    },
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "90px",
      marginTop: "10px",
      color: "black",
    },
    titleE: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      marginBottom: "15px",
      color: "lightseagreen",
    },
    typography: {
      marginBottom: "15px",
    },
    offer: {
      color: "dodgerblue",
      fontSize: "18px",
      marginBottom: "15px",
    },
    date: {
      color: "dodgerblue",
    },
    created: {
      fontSize: "10px",
      marginBottom: "15px",
      marginTop: "15px",
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "15px",
    },
    buscar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "24px",
      width: "97%",
      marginBottom: "15px",
      border: "1px solid black",
      borderRadius: "10px",
    },
    title: {
      fontSize: "20px",
      color: "lightseagreen",
      marginBottom: "7px",
    },
    timeToDo: {
      color: "dodgerblue",
      fontSize: "15px",
      marginBottom: "7px",
    },
    notifi: {
      color: "grey",
      fontSize: "20px",
      marginBottom: "7px",
    },
    messageN: {
      textAlign: "center",
      marginTop: "20px",
    },
  };

  // Si el userType es 'freelancer', no mostrar notificaciones
  if (userType === "freelancer") {
    return (
      <Container>
        <AppBar position="fixed" sx={{ bgcolor: "white" }}>
          <Toolbar
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Atras color="inherit" />
            <Container style={styles.user}>
              <Typography variant="h5" color={"black"} gutterBottom>
                Notificaciones
              </Typography>
            </Container>
          </Toolbar>
        </AppBar>
        <Box style={styles.contFilt}>
          <div style={styles.buscar}>
            <TextField
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Buscar..."
              sx={{
                width: "90%",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#312783",
                  },
                  "&:hover fieldset": {
                    borderColor: "dodgerblue",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "dodgerblue",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#312783",
                  "&.Mui-focused": {
                    color: "dodgerblue",
                  },
                },
              }}
            />
          </div>
        </Box>
        <Container sx={{ mb: 20 }}>
          <Typography style={styles.messageN}>
            Aún no llegaron notificaciones.
          </Typography>
          <BottomNav />
        </Container>
      </Container>
    );
  }

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "white" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color={"black"} gutterBottom>
              Notificaciones
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Box style={styles.contFilt}>
        <div style={styles.buscar}>
          <TextField
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Buscar..."
            sx={{
              width: "90%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#312783",
                },
                "&:hover fieldset": {
                  borderColor: "dodgerblue",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "dodgerblue",
                },
              },
              "& .MuiInputLabel-root": {
                color: "#312783",
                "&.Mui-focused": {
                  color: "dodgerblue",
                },
              },
            }}
          />
        </div>
      </Box>
      <Container sx={{ mb: 20 }}>
        {loading ? (
          <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {filteredProposals.length > 0 ? (
              <List>
                {filteredProposals.map((proposal) => (
                  <ListItem key={proposal.id}>
                    <div style={styles.card}>
                      <Typography style={styles.notifi}>
                        Nueva propuesta para el proyecto:
                      </Typography>
                      <Typography variant="body2" style={styles.title}>
                        {proposal.projectTitle}
                      </Typography>
                      <Typography variant="body2" style={styles.timeToDo}>
                        Propuesta enviada el:{" "}
                        {proposal.sentAt ? formatDate(proposal.sentAt) : "N/A"}
                      </Typography>
                      <Typography variant="body2" style={styles.timeToDo}>
                        Fecha de entrega:{" "}
                        {proposal.timeToDo
                          ? formatDate(proposal.timeToDo)
                          : "N/A"}
                      </Typography>
                      <Button href="/propuestas" variant="outlined" fullWidth>
                        Ir a Mis propuestas
                      </Button>
                    </div>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No se encontraron notificaciones.</Typography>
            )}
          </>
        )}
        <BottomNav />
      </Container>
    </Container>
  );
};

export default Notifications;
