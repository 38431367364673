import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Toolbar,
  AppBar,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import Atras from "./atras";
import { collection, doc, getDocs, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import BottomNav from "./BottomNav";

const Memberships = () => {
  const [selectedMembership, setSelectedMembership] = useState("");
  const [userMemberships, setUserMemberships] = useState({});

  const memberships = [
    {
      id: "Membresia_1",
      name: "Membresía 1",
      description: "3 proyectos por mes.",
      price: "ARS $4000/mes",
      offer: "Los primeros 7 días gratis.",
      url: "https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848f324171018f3528f7100078",
    },
    {
      id: "Membresia_2",
      name: "Membresía 2",
      description: "10 proyectos por mes.",
      price: "ARS $8000/mes",
      offer: "Los primeros 14 días gratis.",
      url: "https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848f324171018f3529a5a30079",
    },
    {
      id: "Membresia_3",
      name: "Membresía 3",
      description: "Proyectos ilimitados.",
      price: "ARS $12000/mes",
      offer: "El primer mes gratis.",
      url: "https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c9380848f324196018f3529e2d8007a",
    },
  ];

  const currentUser = auth.currentUser;
  useEffect(() => {
    const fetchUserMembership = async () => {
      try {
        const userRef = doc(db, "users", currentUser.uid);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
          // eslint-disable-next-line no-unused-vars
          const userData = userSnapshot.data();
          const membershipsRef = collection(userRef, "memberships");
          const membershipsSnapshot = await getDocs(membershipsRef);
          const userMemberships = {};

          membershipsSnapshot.forEach((doc) => {
            const membershipData = doc.data();
            userMemberships[doc.id] = {
              ...membershipData,
              membershipId: doc.id, // Agregar el membershipId al objeto de membresía
            };
          });

          setUserMemberships(userMemberships);
        } else {
          console.log("No se encontraron membresías para el usuario.");
        }
      } catch (error) {
        console.error("Error al obtener membresías del usuario:", error);
      }
    };

    fetchUserMembership();
  }, [currentUser.uid]);

  const handleMembershipChange = (membershipId) => {
    setSelectedMembership(membershipId);
  };

  const handleConfirm = async () => {
    const selected = memberships.find(
      (membership) => membership.id === selectedMembership
    );
    if (selected) {
      const userRef = collection(db, "users", currentUser.uid, "memberships");
      const docRef = doc(userRef);

      // Agrega un documento a la colección con el estado de pago como "pendiente"
      await setDoc(docRef, {
        name: selected.id,
        paymentStatus: "pendiente",
      });
      window.location.href = selected.url;
    } else {
      console.error("Error de redirección...");
    }
  };

  const renderMembershipStatus = (membershipId) => {
    const status = userMemberships[membershipId]?.paymentStatus;

    if (!membershipId) { // Nuevo: Verificación de membershipId
      return <span style={{ color: "gray" }}>Aún no has seleccionado una membresía.</span>;
    }

    if (!status) {
      return (
        <span style={{ color: "gray" }}>Estado de suscripción no disponible.</span>
      );
    } else if (status === "pendiente") {
      return (
        <span style={{ color: "orange" }}>Tu pago aún no se ha procesado.</span>
      );
    } else if (status === "pagado") {
      return (
        <span style={{ color: "lightgreen" }}>Tu membresía está activa.</span>
      );
    } else {
      return (
        <span style={{ color: "red" }}>Tu membresía ha sido cancelada.</span>
      );
    }
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      backgroundColor: "white",
      height: "100vh",
    },
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "90px",
      marginTop: "10px",
      color: "black",
    },
    membershipContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "98%",
      marginTop: "20px",
      paddingBottom: "10px",
      textAlign: "left",
      backgroundColor: "white",
    },
    button: {
      display: "block",
      margin: "20px auto",
      width: "90%",
      marginBottom: "130px"
    },
    check: {
      display: "flex",
      justifyContent: "flex-start",
      margin: "15px 0px",
      backgroundColor: "white",
      border: "1px solid black",
      borderRadius: "10px",
      color: "black",
      width: "100%",
      padding: "20px",
    },
    title: {
      color: "dodgerblue",
    },
    offer: {
      color: "dodgerblue",
    },
    status: {
      color: "lightseagreen",
    },
    boxStatus: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      margin: "15px 0px",
      backgroundColor: "black",
      borderRadius: "3px",
      color: "lightgreen",
      width: "100%",
      padding: "20px",
    },
    estadoT: {
      padding: "10px",
    },
    offers: {
      color: "green"
    }
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "dodgerblue" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Membresías
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 16 }}>
        <Container sx={styles.membershipContainer}>
          {memberships.map((membership) => (
            <FormControlLabel
              key={membership.id}
              sx={styles.check}
              control={
                <Checkbox
                  sx={{ color: "black" }}
                  checked={selectedMembership === membership.id}
                  onChange={() => handleMembershipChange(membership.id)}
                />
              }
              label={
                <div>
                  <Typography sx={styles.title} variant="h6">
                    {membership.name}
                  </Typography>
                  <Typography>{membership.description}</Typography>
                  <Typography sx={styles.offer}>{membership.price}</Typography>
                  <Typography sx={styles.offers}>{membership.offer}</Typography>
                </div>
              }
            />
          ))}
          {Object.keys(userMemberships).length === 0 && ( // Verifica si no hay membresías asociadas al usuario
            <Box sx={styles.boxStatus}>
              <Typography style={{ color: "gray" }}>
                Aún no tienes ninguna membresía.
              </Typography>
            </Box>
          )}
          {Object.entries(userMemberships).map(([membershipId, membershipData]) => (
              <Box sx={styles.boxStatus} key={membershipId}>
              <Typography sx={{ color: "dodgerblue" }}>
                Tienes la membresía:
              </Typography>{" "}
              {membershipData.name}{" "}
              {/* Aquí se muestra el nombre de la membresía */}
              <Typography sx={{ color: "dodgerblue" }}>
                Estado de suscripción:<br></br>
              </Typography>
              {renderMembershipStatus(membershipId)}{" "}
              {/* Usar el membershipId para obtener el estado */}
              </Box>
          ))}
        </Container>
        <Box sx={{ display: "block", textAlign: "center", width: "85%", padding: "15px", margin: "auto", border: "1.5px solid black", borderRadius: "5px" }}>
          <Typography sx={{ color: "red" }}>¡Importante!</Typography>
          <Typography>Luego de realizar el pago, por favor hacer click en "Volver al sitio.."</Typography>
        </Box>
        <Button variant="contained" sx={styles.button} onClick={handleConfirm}>
          Confirmar
        </Button>
      </Container>
      <BottomNav />
    </Container>
  );

};

export default Memberships;
