import {
  Alert,
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { CardMedia } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInUser, resetPassword } from "../firebase";
import { isLoggedIn } from "../session";
import logoSvg from "../imgs/logo.png";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from "@mui/material";


export default function Login() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();

    // validate the inputs
    if (!email || !password) {
      setError("Por favor ingrese su usuario y contraseña.");
      return;
    }

    // clear the errors
    setError("");

    // TODO: send the login request
    console.log("Iniciando sesión...");
    try {
      let loginResponse = await signInUser(email, password);
      isLoggedIn(loginResponse.user);
      navigate("/home");
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Por favor ingrese su dirección de correo electrónico.");
      return;
    }

    try {
      await resetPassword(email);
      setError(""); // Clear any previous errors
      alert("Email de restablecimiento de contraseña enviado con éxito.");
      // Optionally, you can provide a user-friendly message here.
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const styles = {
    media: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "113px",
      height: "auto",
      paddingTop: "13%", // 16:9,
      marginTop: "0px",
      marginBottom: "30px",
    },
    inicio: {
      fontWeight: "300",
      fontSize: "18px",
    },
    google: {
      width: "20px",
      paddingRight: "15px"
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 2 }}>
      <CardMedia
        component="img"
        image={logoSvg} // Utilizar la variable que contiene la imagen SVG
        title="Skach"
        style={styles.media} // Especificar estilos si es necesario
      />
      <Typography
        variant="h5"
        component="h5"
        style={styles.inicio}
        gutterBottom
        textAlign="center"
      >
        Inicio de sesión
      </Typography>
      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          {error}
        </Alert>
      )}
      <Box component="form" onSubmit={onSubmit}>
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mt: 1 }}
          fullWidth
        />
        <TextField
          label="Contraseña"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mt: 3 }}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          fullWidth
        />
        <Button variant="contained" type="submit" sx={{ mt: 3 }} fullWidth>
          Iniciar sesión
        </Button>
        <Link
          href="#"
          onClick={handleForgotPassword}
          sx={{ mt: 1, display: "block", textAlign: "center" }}
        >
          Olvidé mi contraseña
        </Link>
        <Box sx={{ mt: 2, display: "block", textAlign: "center" }}>
          ¿Aún no tienes cuenta? <Link href="/register">Crear una cuenta</Link>
        </Box>
      </Box>
    </Container>
  );
}
