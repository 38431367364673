import React from 'react';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const Atras = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navegar hacia atrás
  };

  return (
    <Paper>
      <IconButton onClick={handleGoBack} color="primary">
        <ArrowBackIcon />
      </IconButton>
    </Paper>
  );
};

export default Atras;
