import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { db, auth } from "../firebase";
import { doc, collection, setDoc, serverTimestamp } from "firebase/firestore";
import Atras from "./atras";

const PublishProposal = () => {
  const [formData, setFormData] = useState({
    description: "",
    price: "",
    timeToComplete: "",
  });
  const [publishSuccess, setPublishSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);

  // Get projectId from query parameters
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('proyectId');

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setUser(currentUser);
      } else {
        console.error("No user is signed in");
      }
    };

    fetchUser();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleAddProposal = async (e) => {
    e.preventDefault();
  
    if (!projectId) {
      console.error("Error: No project ID provided in the URL.");
      return;
    }
  
    if (!user) {
      console.error("No user is signed in");
      return;
    }
  
    try {
      const projectRef = doc(db, "projects", projectId);
      const proposalRef = doc(collection(projectRef, "proposals"));
  
      await setDoc(proposalRef, {
        proposalId: proposalRef.id,
        projectId: projectId,
        clientUid: projectId, // Make sure this is correct
        freelanceUid: user.uid,
        description: formData.description,
        price: formData.price,
        timeToComplete: formData.timeToComplete,
        timestamp: serverTimestamp(),
      });
  
      await setDoc(doc(db, "proposals", proposalRef.id), {
        proposalId: proposalRef.id,
        projectId: projectId,
        clientUid: projectId, // Make sure this is correct
        freelanceUid: user.uid,
        description: formData.description,
        price: formData.price,
        timeToComplete: formData.timeToComplete,
        timestamp: serverTimestamp(),
      });
  
      console.log("Propuesta agregada exitosamente");
      setPublishSuccess(true);
    } catch (error) {
      console.error("Error al agregar la propuesta:", error.message);
    }
  };


  const handleRedirectHome = () => {
    navigate("/home");
  };

  const styles = {
    uploadButton: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "50px",
      backgroundColor: "#f0f0f0",
      border: "2px solid #312783",
      marginTop: "20px",
      borderRadius: "8px",
      color: "black",
    },
    progress: {
      position: "absolute",
      color: "#312783",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "dodgerblue" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Enviar propuesta
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 14 }}>
        <TextField
          autoFocus
          margin="dense"
          id="description"
          label="Descripción de la propuesta"
          type="text"
          fullWidth
          name="description"
          multiline
          rows={5}
          value={formData.description}
          onChange={handleInputChange}
          className={styles.textField}
        />
        <TextField
          margin="dense"
          id="price"
          label="Presupuesto"
          type="text"
          fullWidth
          name="price"
          value={formData.price}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          id="timeToComplete"
          label="Tiempo estimado para completar (días)"
          type="text"
          fullWidth
          name="timeToComplete"
          value={formData.timeToComplete}
          onChange={handleInputChange}
        />

        {publishSuccess && (
          <Box sx={{ textAlign: "center" }}>
            <Typography>La propuesta se ha enviado correctamente.</Typography>
            <Button onClick={handleRedirectHome} color="primary">
              Ir a Inicio
            </Button>
          </Box>
        )}
        {!publishSuccess && (
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            fullWidth
            onClick={handleAddProposal}
            color="primary"
          >
            Publicar
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default PublishProposal;
