/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Box,
  Typography,
  List,
  ListItem,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { Task as TaskIcon, Work as WorkIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db, getToken } from "../firebase";
import { collection, getDocs, where, query } from "firebase/firestore";
import BottomNav from "./BottomNav";

import logoSvg from "../imgs/logo.svg";

const Proposals = () => {
  const [uid, setUid] = useState(null);
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState("");
  const [authUserId, setAuthUserId] = useState(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([
    "Diseño Grafico",
    "Programación",
    "Marketing Digital",
    "Redaccion De Contenidos",
    "Traducción",
    "Diseño Web",
  ]);

  useEffect(() => {
    const fetchAuthUserId = async () => {
      const user = await isLoggedIn();
      if (user) {
        setAuthUserId(user.uid);
        console.log("Authenticated User ID:", user.uid);
      } else {
        console.log("No authenticated user found.");
        navigate("/login"); // Redirect to login if not authenticated
      }
    };
    fetchAuthUserId();
  }, [navigate]);

  const handleProductClick = (projectId, proposalId, projectUid) => {
    console.log("Project ID:", projectId);
    console.log("Proposal ID:", proposalId);
    console.log("Project UID:", projectUid);
    if (projectId && proposalId && projectUid && authUserId) {
      navigate(
        `/chatbox/${projectId}/${proposalId}/${projectUid}/${authUserId}`
      );
    } else {
      console.error("Missing required parameters for navigation");
      // Opcional: Mostrar un mensaje de error al usuario
    }
  };

  const formatDate = (timeToDo) => {
    if (timeToDo instanceof Date) {
      return timeToDo.toLocaleDateString();
    } else if (timeToDo && timeToDo.toDate instanceof Function) {
      return timeToDo.toDate().toLocaleDateString();
    }
    return "";
  };

  useEffect(() => {
    const checkAuthAndFetchProposals = async () => {
      if (!isLoggedIn()) {
        navigate("/login");
        return;
      }

      try {
        const token = await getToken();
        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }
        const { uid } = currentUser;
        setUid(uid);
        setAuthUserId(uid); // Set authUserId to the current user's uid
        setLoading(true);

        const projectsSnapshot = await getDocs(collection(db, "projects"));
        const projectsData = projectsSnapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          skills: doc.data().skills,
          timeToDo: doc.data().timeToDo,
          uid: doc.data().uid,
        }));

        const allProposals = [];
        for (const project of projectsData) {
          const proposalsSnapshot = await getDocs(
            collection(db, "projects", project.id, "proposals")
          );
          const projectProposals = proposalsSnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              projectId: project.id,
              projectTitle: project.title,
              projectSkills: project.skills,
              projectUid: project.uid,
              timeToDo: project.timeToDo ? project.timeToDo.toDate() : null,
              sentAt: data.timestamp ? data.timestamp.toDate() : null,
              uid: data.uid,
            };
          });
          allProposals.push(...projectProposals);
        }

        setProposals(allProposals);
        setLoading(false);
        console.log("Propuestas obtenidas:", allProposals);
      } catch (error) {
        console.error("Error al obtener propuestas:", error);
        setLoading(false);
      }
    };
    checkAuthAndFetchProposals();
  }, [navigate]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    console.log("Nueva categoría seleccionada:", e.target.value);
    setSkills(e.target.value);
  };

  const filteredProposals = proposals.filter((proposal) => {
    return (
      proposal.projectTitle &&
      proposal.projectTitle.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (skills ? proposal.projectSkills === skills : true)
    );
  });

  const isProposalUnread = async (proposalId, projectId) => {
    try {
      const unreadQuery = query(
        collection(
          db,
          "projects",
          projectId,
          "proposals",
          proposalId,
          "messages"
        ),
        where("read", "==", false),
        where("senderId", "!=", authUserId)
      );
      const unreadSnapshot = await getDocs(unreadQuery);
      return unreadSnapshot.docs.length > 0;
    } catch (error) {
      console.error("Error checking unread messages:", error);
      return false;
    }
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "150px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      color: "black",
      fontSize: "14px",
      fontWeight: "bold",
      paddingBottom: "-5px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      marginTop: "20px",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      backgroundColor: "#fff",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "95%",
      border: "0.5px solid #ccc",
      margin: "15px auto",
      boxShadow: "0px 0px 3px 0px black",
    },
    typography: {
      marginBottom: "0px",
    },
    textField: {
      marginBottom: "15px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      width: "95%",
      height: "35px",
      marginTop: "45px",
      padding: "5px",
      fontSize: "14px",
      border: "3px solid grey",
      borderRadius: "4px",
    },
    buscar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "24px",
      width: "97%",
      marginBottom: "20px",
      border: "1px solid black",
      borderRadius: "10px",
    },
    name: {
      color: "#312783",
      fontSize: "25px",
      fontWeight: "bold",
    },
    title: {
      fontSize: "24px",
      color: "dodgerblue",
      marginBottom: "7px",
    },
    description: {
      fontSize: "18px",
      marginBottom: "7px",
    },
    timeToDo: {
      color: "lightseagreen",
      fontSize: "15px",
      marginBottom: "7px",
    },
    skills: {
      marginBottom: "7px",
    },
    price: {
      color: "lightseagreen",
      marginBottom: "7px",
    },
    created: {
      fontSize: "10px",
      marginBottom: "7px",
    },
    media: {
      width: "190px",
      marginTop: "25px",
    },
  };

  return (
    <Container>
      <AppBar position="absolute" sx={{ bgcolor: "#fff" }}>
        <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
          <CardMedia
            component="img"
            image={logoSvg}
            title="Skach"
            style={styles.media}
          />
          <Box>
            <Typography variant="h6" sx={styles.slogan}>
              ¡Crea un proyecto o busca uno!
            </Typography>
          </Box>
          <Toolbar sx={styles.iconContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/proyectos" color="#000">
                <WorkIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Mis Proyectos
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/propuestas" color="#000">
                <TaskIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Mis Propuestas
              </Typography>
            </div>
          </Toolbar>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 26, mb: 15 }}>
        <Box sx={styles.buscar}>
          <TextField
            label="Buscar por nombre de proyecto..."
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined"
            sx={{
              width: "70%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#312783",
                },
                "&:hover fieldset": {
                  borderColor: "dodgerblue",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "dodgerblue",
                },
              },
              "& .MuiInputLabel-root": {
                color: "#312783",
                "&.Mui-focused": {
                  color: "dodgerblue",
                },
              },
            }}
          />
          <TextField
            select
            label="Habilidad"
            value={skills}
            onChange={handleCategoryChange}
            variant="outlined"
            sx={{
              width: "28%",
              ml: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#312783",
                },
                "&:hover fieldset": {
                  borderColor: "dodgerblue",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "dodgerblue",
                },
              },
              "& .MuiInputLabel-root": {
                color: "#312783",
                "&.Mui-focused": {
                  color: "dodgerblue",
                },
              },
            }}
          >
            <MenuItem value={null}>Habilidad</MenuItem>
            {categories.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {loading ? (
          <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {filteredProposals.length > 0 ? (
              <List>
                {filteredProposals.map((proposal) => (
                  <ListItem key={proposal.id}>
                    <div style={styles.card}>
                      <Typography variant="h6" style={styles.title}>
                        {proposal.projectTitle}
                      </Typography>

                      <Typography variant="body2" style={styles.timeToDo}>
                        Propuesta enviada el:{" "}
                        {proposal.sentAt ? formatDate(proposal.sentAt) : "N/A"}
                      </Typography>
                      <Typography variant="body1" style={styles.description}>
                        Descripción: {proposal.description}
                      </Typography>
                      <Typography variant="body2" style={styles.price}>
                        Presupuesto: $
                        <span style={styles.price}>{proposal.price}</span>
                      </Typography>
                      <Typography variant="body2" style={styles.skills}>
                        Habilidad: {proposal.projectSkills}
                      </Typography>
                      <Typography variant="body2" style={styles.timeToDo}>
                        Fecha de entrega:{" "}
                        {proposal.timeToDo
                          ? formatDate(proposal.timeToDo)
                          : "N/A"}
                      </Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() =>
                          handleProductClick(
                            proposal.projectId,
                            proposal.projectUid,
                            authUserId
                          )
                        }
                      >
                        Chatear
                      </Button>
                    </div>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No se encontraron propuestas.</Typography>
            )}
          </>
        )}
      </Container>
      <BottomNav />
    </Container>
  );
};

export default Proposals;
