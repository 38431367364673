import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase"; // Make sure to configure your firebase file
import {
  collection,
  query,
  where,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Box,
  CircularProgress,
  IconButton,
  Container,
  AppBar,
  CardMedia,
  Toolbar,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import logoSvg from "../imgs/logo.svg";
import { Work as WorkIcon, Task as TaskIcon } from "@mui/icons-material";
import BottomNav from "./BottomNav";

const skillsOptions = [
  "Diseño Gráfico",
  "Programación",
  "Marketing Digital",
  "Redacción de Contenidos",
  "Traducción",
  "Diseño Web",
];

const ProyectsComponent = () => {
  const [proyects, setProyects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentProyect, setCurrentProyect] = useState(null);
  const [editData, setEditData] = useState({
    title: "",
    description: "",
    price: "",
    skills: [],
    timeToDo: new Date(),
  });
  const [skills, setSkills] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [categories, setCategories] = useState([
    "Diseño Grafico",
    "Programación",
    "Marketing Digital",
    "Redaccion De Contenidos",
    "Traducción",
    "Diseño Web",
  ]);
  const navigate = useNavigate();

  const isLoggedIn = () => {
    return !!auth.currentUser;
  };

  const getToken = async () => {
    if (auth.currentUser) {
      return auth.currentUser.getIdToken();
    }
    return null;
  };

  useEffect(() => {
    const checkAuthAndFetchProyects = async () => {
      if (!isLoggedIn()) {
        navigate("/login");
        return;
      }

      try {
        const token = await getToken();

        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }

        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }
        setLoading(true);
        const { uid } = currentUser;
        const q = query(collection(db, "projects"), where("uid", "==", uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const proyectsData = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              title: data.title,
              description: data.description,
              price: data.price,
              skills: data.skills, // Ensure skills is an array
              timeToDo: data.timeToDo.toDate(),
              createdAt: data.createdAt.toDate(),
            };
          });
          setProyects(proyectsData);
          setLoading(false);
          console.log("Proyectos obtenidos:", proyectsData);
        });
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener proyectos:", error);
        setLoading(false);
      }
    };
    checkAuthAndFetchProyects();
  }, [navigate]);

  const handleEdit = async () => {
    try {
      const proyectRef = doc(db, "projects", currentProyect.id);
      await updateDoc(proyectRef, editData);
      console.log("Proyecto actualizado:", editData);
      setIsDialogOpen(false);
      setEditData({
        title: "",
        description: "",
        price: "",
        skills: [],
        timeToDo: new Date(),
      });
    } catch (error) {
      console.error("Error al actualizar el proyecto:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const proyectRef = doc(db, "projects", id);
      await deleteDoc(proyectRef);
      console.log("Proyecto eliminado:", id);
    } catch (error) {
      console.error("Error al eliminar el proyecto:", error);
    }
  };

  const openDialog = (proyect) => {
    setCurrentProyect(proyect);
    setEditData({
      title: proyect.title,
      description: proyect.description,
      price: proyect.price,
      skills: Array.isArray(proyect.skills) ? proyect.skills : [], // Ensure skills is an array
      timeToDo: proyect.timeToDo,
    });
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setEditData({
      title: "",
      description: "",
      price: "",
      skills: [],
      timeToDo: new Date(),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSkillsChange = (event) => {
    const {
      target: { value },
    } = event;
    setEditData((prevData) => ({
      ...prevData,
      skills: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleDateChange = (date) => {
    setEditData((prevData) => ({
      ...prevData,
      timeToDo: date,
    }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    console.log("Nueva categoría seleccionada:", e.target.value);
    setSkills(e.target.value);
  };

  const filteredProyects = proyects.filter((proyect) => {
    return (
      proyect.title &&
      proyect.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (skills ? proyect.skills.includes(skills) : true) // Corrected this line
    );
  });

  const formatDate = (timeToDo) => {
    if (timeToDo instanceof Date) {
      return timeToDo.toLocaleDateString();
    } else if (timeToDo && timeToDo.toDate instanceof Function) {
      return timeToDo.toDate().toLocaleDateString();
    }
    return "";
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "150px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      color: "black",
      fontSize: "14px",
      fontWeight: "bold",
      paddingBottom: "-5px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      backgroundColor: "#fff",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "95%",
      margin: "9px auto",
    },
    typography: {
      marginBottom: "0px",
    },
    inputS: {
      width: "95%",
      height: "35px",
      marginTop: "20px",
      padding: "5px",
      fontSize: "14px",
      border: "3px solid grey",
      borderRadius: "4px",
    },
    buscar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "24px",
      width: "97%",
      marginBottom: "20px",
      border: "1px solid black",
      borderRadius: "10px",
    },
    name: {
      color: "#312783",
      fontSize: "25px",
      fontWeight: "bold",
    },
    title: {
      fontSize: "24px",
      color: "dodgerblue",
      marginBottom: "7px",
    },
    description: {
      fontSize: "18px",
      marginBottom: "7px",
    },
    timeToDo: {
      color: "lightseagreen",
      fontSize: "15px",
      marginBottom: "7px",
    },
    skills: {
      marginBottom: "7px",
    },
    price: {
      color: "lightseagreen",
      marginBottom: "7px",
    },
    created: {
      fontSize: "10px",
      marginBottom: "7px",
    },
    media: {
      width: "190px",
      marginTop: "25px",
    },
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar
          sx={{ flexDirection: "column", width: "100%", alignItems: "center" }}
        >
          <CardMedia
            component="img"
            image={logoSvg} // Utilizar la variable que contiene la imagen SVG
            title="Skach"
            style={styles.media} // Especificar estilos si es necesario
          />
          <Box>
            <Typography variant="h6" sx={styles.slogan}>
              ¡Crea un proyecto o busca uno!
            </Typography>
          </Box>
          <Toolbar sx={styles.iconContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/proyectos" color="#000">
                <WorkIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Mis Proyectos
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/propuestas" color="#000">
                <TaskIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Mis Propuestas
              </Typography>
            </div>
          </Toolbar>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 23, mb: 20 }}>
        <Box sx={styles.buscar}>
          <TextField
            label="Buscar por nombre de proyecto..."
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined"
            sx={{
              width: "70%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#312783",
                },
                "&:hover fieldset": {
                  borderColor: "dodgerblue",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "dodgerblue",
                },
              },
              "& .MuiInputLabel-root": {
                color: "#312783",
                "&.Mui-focused": {
                  color: "dodgerblue",
                },
              },
            }}
          />
          <TextField
            select
            label="Habilidad"
            value={skills}
            onChange={handleCategoryChange}
            variant="outlined"
            sx={{
              width: "28%",
              ml: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#312783",
                },
                "&:hover fieldset": {
                  borderColor: "dodgerblue",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "dodgerblue",
                },
              },
              "& .MuiInputLabel-root": {
                color: "#312783",
                "&.Mui-focused": {
                  color: "dodgerblue",
                },
              },
            }}
          >
            <MenuItem value={null}>Habilidad</MenuItem>
            {categories.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {loading ? (
          <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {filteredProyects.length > 0 ? (
              <List>
                {filteredProyects.map((proyect) => (
                  <ListItem key={proyect.id}>
                    <div style={styles.card}>
                      <Typography variant="h5" style={styles.title}>
                        {proyect.title}
                      </Typography>
                      <Typography variant="body1" style={styles.description}>
                        {proyect.description}
                      </Typography>
                      <Typography variant="body2" style={styles.price}>
                        Precio: {proyect.price}
                      </Typography>
                      <Typography variant="body2" style={styles.skills}>
                        Habilidades: {proyect.skills}
                      </Typography>
                      <Typography variant="body2" style={styles.timeToDo}>
                        Fecha de entrega: {formatDate(proyect.timeToDo)}
                      </Typography>
                      <Typography variant="body2" style={styles.createdAt}>
                        Creado el: {proyect.createdAt.toLocaleDateString()}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "100%",
                        }}
                      >
                        <Button
                          fullWidth
                          sx={{ marginRight: "15px", marginTop: "15px" }}
                          variant="contained"
                          color="primary"
                          onClick={() => openDialog(proyect)}
                        >
                          Editar
                        </Button>
                        <Button
                          fullWidth
                          sx={{ marginTop: "15px" }}
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDelete(proyect.id)}
                        >
                          Eliminar
                        </Button>
                      </Box>
                    </div>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No se encontraron propuestas.</Typography>
            )}
          </>
        )}
      </Container>
      <BottomNav />
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Editar Proyecto</DialogTitle>
        <DialogContent>
          <TextField
            name="title"
            label="Título"
            variant="outlined"
            fullWidth
            margin="normal"
            value={editData.title}
            onChange={handleChange}
          />
          <TextField
            name="description"
            label="Descripción"
            variant="outlined"
            fullWidth
            margin="normal"
            value={editData.description}
            onChange={handleChange}
          />
          <TextField
            name="price"
            label="Precio"
            variant="outlined"
            fullWidth
            margin="normal"
            value={editData.price}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Habilidades</InputLabel>
            <Select
              multiple
              value={editData.skills}
              onChange={handleSkillsChange}
              input={<OutlinedInput label="Habilidades" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {skillsOptions.map((skill) => (
                <MenuItem key={skill} value={skill}>
                  <Checkbox checked={editData.skills.indexOf(skill) > -1} />
                  <ListItemText primary={skill} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DatePicker
            onChange={handleDateChange}
            value={editData.timeToDo}
            format="y-MM-dd"
            clearIcon={null}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleEdit} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProyectsComponent;
