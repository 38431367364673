/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  CircularProgress,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Work as WorkIcon,
  Task as TaskIcon,
  AddCircle as AddCircleIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import NotificationIcon, { subscribeToNotifications } from "./NotificationIcon";
import { isLoggedIn } from "../session";
import { auth, db, getToken, serverTimestamp } from "../firebase";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  getDocs,
  addDoc,
  where,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import logoSvg from "../imgs/logo.svg";
import SearchIcon from "@mui/icons-material/Search";
import ChatIcon from '@mui/icons-material/Chat';

const Home = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  const [authUserId, setAuthUserId] = useState(null);
  const [skills, setSkills] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [categories, setCategories] = useState([
    "Diseño Grafico",
    "Programación",
    "Marketing Digital",
    "Redaccion De Contenidos",
    "Traducción",
    "Diseño Web",
  ]);
  const navigate = useNavigate();
  const [uid, setUid] = useState(null);
  const [proposals, setProposals] = useState([]);
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogCC, setShowDialogCC] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);


  const [icon, setIcon] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paymentStatus = params.get(
      "mncjshahduayduycychajehdjxnsanmxncxkcoasodasdkjamxxcv"
    );

    if (paymentStatus === "pagado") {
      // Función para actualizar el estado de pago en la base de datos
      const updatePaymentStatus = async (status) => {
        try {
          const { uid } = auth.currentUser || {};
          if (uid) {
            const userDocRef = doc(db, "users", uid);
            const membershipRef = collection(userDocRef, "memberships");
            const membershipSnapshot = await getDocs(membershipRef);

            membershipSnapshot.forEach(async (membershipDoc) => {
              try {
                const membershipData = membershipDoc.data();
                const name = membershipData.name;

                // Si el nombre de la membresía es "Membresia_1", "Membresia_2" o "Membresia_3", actualizar el estado de pago
                if (
                  name === "Membresia_1" ||
                  name === "Membresia_2" ||
                  name === "Membresia_3"
                ) {
                  await updateDoc(membershipDoc.ref, {
                    clicks: "0",
                    paymentStatus: status,
                  });
                }
              } catch (error) {
                console.error("Error updating membership document:", error);
              }
            });

            setShowDialog(true);
          }
        } catch (error) {
          console.error("Error updating user payment status:", error);
        }
      };

      // Llama a la función para actualizar el estado de pago
      updatePaymentStatus("pagado");
    }
  }, []);

  useEffect(() => {
    const fetchMembership = async () => {
      try {
        const { uid } = auth.currentUser || {};
        if (uid) {
          const userDocRef = doc(db, "users", uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            if (userData.userType === "cliente") {
              const membershipRef = collection(userDocRef, "memberships");
              const membershipSnapshot = await getDocs(membershipRef);
              // Verificar si la subcolección memberships existe
              if (membershipSnapshot.empty) {
                setShowDialogCC(true);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching memberships:", error);
      }
    };

    fetchMembership();
  }, []);

  useEffect(() => {
    const fetchIcon = async () => {
      try {
        const result = await getIconForUserType();
        setIcon(result);
      } catch (error) {
        console.error("Error fetching icon:", error);
        // Puedes manejar el error de manera adecuada, como mostrar un ícono predeterminado
      }
    };

    fetchIcon();
  });

  const getIconForUserType = async () => {
    try {
      const { uid } = auth.currentUser || {};
      if (!uid) {
        return null;
      }

      // Fetch user data
      const userDocRef = doc(db, "users", uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        const userType = userData.userType;

        // Fetch memberships
        const membershipsRef = collection(userDocRef, "memberships");
        const membershipsSnapshot = await getDocs(membershipsRef);

        for (const doc of membershipsSnapshot.docs) {
          const membershipData = doc.data();

          if (membershipData.paymentStatus === "pagado") {
            const name = membershipData.name;
            let membershipCount = 0;

            if (name === "Membresia_1") {
              membershipCount = 12; // Membresia1: 4 usos
            } else if (name === "Membresia_2") {
              membershipCount = 40; // Membresia2: 10 usos
            } else if (name === "Membresia_3") {
              membershipCount = Infinity; // Membresia3: ilimitado
            }

            if (userType === "cliente" && membershipCount > 0) {
              // Comprobar el número de clics
              if (membershipData.clicks < membershipCount) {
                // Renderizar el icono solo si quedan clics disponibles
                const icon = (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      href="/publish"
                      onClick={() => handleClick(doc.ref, membershipData)}
                    >
                      <AddCircleIcon sx={{ color: "#312783" }} />
                    </IconButton>
                    <Typography variant="caption" fontSize={9} align="center">
                      Publicar
                    </Typography>
                  </div>
                );
                return icon;
              } else {
                // Aquí puedes renderizar un icono diferente para indicar que no hay más clics disponibles
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    href="/publish"
                    onClick={() => handleClick(doc.ref, membershipData)}
                  >
                    <AddCircleIcon sx={{ color: "grey" }} />
                  </IconButton>
                  <Typography variant="caption" fontSize={9} align="center">
                    Publicar
                  </Typography>
                </div>;
                return icon;
              }
            }
          }
        }
      }
      return null;
    } catch (error) {
      console.error("Error fetching icon:", error.message);
      return null;
    }
  };

  const handleClick = async (docRef, membershipData) => {
    setOpen(true);
    try {
      // Incrementar el contador de clics en la membresía
      await updateDoc(docRef, {
        clicks: membershipData.clicks
          ? String(Number(membershipData.clicks) + 1)
          : "1",
      });
    } catch (error) {
      console.error("Error updating clicks:", error.message);
    }
  };

  useEffect(() => {
    const checkAuthAndFetchProposals = async () => {
      if (!isLoggedIn()) {
        navigate("/login");
        return;
      }

      try {
        const token = await getToken();
        if (!token) {
          console.error("No se pudo obtener el token de autenticación.");
          return;
        }
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error("No hay un usuario autenticado.");
          return;
        }
        const { uid } = currentUser;
        setUid(uid);
        setAuthUserId(uid); // Set authUserId to the current user's uid
        setLoading(true);

        // Obtener el userType desde la colección users
        const userDocRef = doc(db, "users", uid);
        const userDocSnapshot = await getDoc(userDocRef);
        if (!userDocSnapshot.exists()) {
          console.error("El documento de usuario no existe.");
          return;
        }
        const userData = userDocSnapshot.data();
        if (!userData.userType) {
          console.error("No se encontró userType para el usuario.");
          return;
        }
        const userType = userData.userType;

        const projectsSnapshot = await getDocs(collection(db, "proyects"));
        const allProposals = [];
        for (const projectDoc of projectsSnapshot.docs) {
          const projectId = projectDoc.id;
          const projectData = projectDoc.data(); // Obtener datos del proyecto
          const proposalsSnapshot = await getDocs(
            collection(db, "proyects", projectId, "proposals")
          );
          const projectProposals = proposalsSnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              uid: data.uid,
              projectId: projectId,
            };
          });
          allProposals.push(...projectProposals);
        }

        setProposals(allProposals);
        

        // Crear notificaciones para las nuevas propuestas
        const newNotifications = [];
        let hasUnreadNotifications = false;
        for (const proposal of allProposals) {
          // Evitar crear notificación para el propio freelancer
          if (proposal.uid === uid) {
            continue;
          }
          const notificationQuery = query(
            collection(db, "notifications"),
            where("idDoc", "==", proposal.id)
          );
          const notificationSnapshot = await getDocs(notificationQuery);
          if (notificationSnapshot.empty) {
            // Obtener el id del usuario dueño del proyecto (cliente)
            const projectDocRef = doc(db, "proyects", proposal.projectId);
            const projectDocSnapshot = await getDoc(projectDocRef);
            if (!projectDocSnapshot.exists()) {
              console.error("El documento del proyecto no existe.");
              continue;
            }
            const projectData = projectDocSnapshot.data();
            const clientUid = projectData.uid; // Suponiendo que el campo uid representa al dueño del proyecto

            // Crear una notificación solo si el usuario autenticado es el dueño del proyecto
            if (userType === "cliente" && uid === clientUid) {
              const notificationData = {
                userId: clientUid, // Ahora, userId es el dueño del proyecto
                idDoc: proposal.id,
                userType: userType,
                read: false,
              };
              newNotifications.push(notificationData);
              // Guardar la notificación en Firestore
              await addDoc(collection(db, "notifications"), notificationData);
            console.log(
              "Notificación creada correctamente:",
              notificationData
            );

            hasUnreadNotifications = true; // Marcar que hay notificaciones no leídas
            }
          } else {
            console.log(
              "Notificación ya existente para idDoc:",
              `${proposal.projectId}_${proposal.id}`
            );
          }
        }
        setHasNewNotifications(hasUnreadNotifications);
        setLoading(false);
        console.log("Propuestas obtenidas:", allProposals);
      } catch (error) {
        console.error("Error al obtener propuestas:", error);
        setLoading(false);
      }
    };
    checkAuthAndFetchProposals();
  }, [navigate]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const projectsCollectionRef = collection(db, "projects");
        const unsubscribe = onSnapshot(
          query(projectsCollectionRef, orderBy("createdAt", "desc")),
          (snapshot) => {
            const projectsData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setLoading(false);
            setProjects(projectsData);
            console.log("Proyectos obtenidos:", projectsData);
          }
        );
        return () => unsubscribe();
      } catch (error) {
        console.error("Error al obtener proyectos:", error);
        setLoading(false);
      }
    };
    fetchProjects();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    console.log("Nueva categoría seleccionada:", e.target.value);
    setSkills(e.target.value);
  };

  useEffect(() => {
    const fetchAuthUserId = async () => {
      const isAuthenticated = await isLoggedIn();
      const { uid } = auth.currentUser || {};
      setAuthUserId(isAuthenticated ? uid : null);
    };
    fetchAuthUserId();
  }, []);

  const filteredProyects = projects.filter((project) => {
    return (
      project.title &&
      project.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (skills ? project.skills === skills : true)
    );
  });

  const formatDate = (timeToDo) => {
    if (timeToDo instanceof Date) {
      return timeToDo.toLocaleDateString();
    } else if (timeToDo && timeToDo.toDate instanceof Function) {
      return timeToDo.toDate().toLocaleDateString();
    }
    return "";
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    img: {
      maxWidth: "150px",
      maxHeight: "40px",
      display: "block",
      margin: "20px auto",
    },
    slogan: {
      color: "black",
      fontSize: "14px",
      fontWeight: "bold",
      paddingBottom: "-5px",
    },
    iconContainer: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
      color: "black",
    },
    iconContainerL: {
      display: "flex",
      width: "100%",
      borderTop: "1.5px solid black",
      justifyContent: "space-around",
    },
    backSlogan: {
      display: "block",
      width: "100vw",
      height: "19px",
      backgroundColor: "#fff",
      textAlign: "center",
    },
    listItem: {
      display: "block",
      width: "95%",
      border: "0.5px solid #ccc",
      margin: "15px auto",
      boxShadow: "0px 0px 3px 0px black",
    },
    typography: {
      marginBottom: "0px",
    },
    contList: {
      marginTop: "-30px",
    },
    inputS: {
      width: "95%",
      height: "35px",
      marginTop: "20px",
      padding: "5px",
      fontSize: "14px",
      border: "3px solid grey",
      borderRadius: "4px",
    },
    buscar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "24px",
      width: "97%",
      border: "1px solid black",
      borderRadius: "10px",
    },
    name: {
      color: "#312783",
      fontSize: "25px",
      fontWeight: "bold",
    },
    title: {
      fontSize: "24px",
      color: "dodgerblue",
      marginBottom: "7px",
    },
    description: {
      fontSize: "18px",
      marginBottom: "7px",
    },
    timeToDo: {
      color: "lightseagreen",
      fontSize: "15px",
      marginBottom: "7px",
    },
    skills: {
      marginBottom: "7px",
    },
    price: {
      color: "lightseagreen",
      marginBottom: "7px",
    },
    created: {
      fontSize: "10px",
      marginBottom: "7px",
    },
    media: {
      width: "190px",
      marginTop: "25px",
    },
  };

  const classes = {
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: "white",
      color: "black",
      borderTop: "1.5px solid black",
    },
    toolbar: {
      justifyContent: "space-around",
    },
  };

  return (
    <Container>
      <Dialog open={showDialogCC} onClose={() => setShowDialogCC(false)}>
        <DialogTitle>¡Hazte miembro de la red!</DialogTitle>
        <DialogContent>
          <Button href="/membership" fullWidth variant="outlined">
            Obtener Membresia
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>¡Pago exitoso!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Su pago ha sido procesado con éxito.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar
          sx={{ flexDirection: "column", width: "100%", alignItems: "center" }}
        >
          <CardMedia
            component="img"
            image={logoSvg} // Utilizar la variable que contiene la imagen SVG
            title="Skach"
            style={styles.media} // Especificar estilos si es necesario
          />
          <Box>
            <Typography variant="h6" sx={styles.slogan}>
              ¡Crea un proyecto o busca uno!
            </Typography>
          </Box>
          <Toolbar sx={styles.iconContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/proyectos" color="#000">
                <WorkIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Mis Proyectos
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/propuestas" color="#000">
                <TaskIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Mis Propuestas
              </Typography>
            </div>
          </Toolbar>
        </Toolbar>
        <AppBar position="fixed" sx={classes.appBar}>
          <Toolbar sx={classes.toolbar}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/home" color="black">
                <HomeIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Inicio
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/members" color="black">
                <SearchIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Buscar
              </Typography>
            </div>
            {icon}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/chats" color="black">
                <ChatIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Chats
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/notifications" color="black">
                <NotificationIcon hasNewNotifications={hasNewNotifications} />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Notificaciones
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton href="/profile" color="black">
                <AccountCircleIcon />
              </IconButton>
              <Typography variant="caption" fontSize={9} align="center">
                Perfíl
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
      </AppBar>
      <Container sx={{ mt: 23, mb: 20 }}>
        <Box sx={styles.buscar}>
          <TextField
            label="Buscar por nombre de proyecto..."
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined"
            sx={{
              width: "70%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#312783",
                },
                "&:hover fieldset": {
                  borderColor: "dodgerblue",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "dodgerblue",
                },
              },
              "& .MuiInputLabel-root": {
                color: "#312783",
                "&.Mui-focused": {
                  color: "dodgerblue",
                },
              },
            }}
          />
          <TextField
            select
            label="Habilidad"
            value={skills}
            onChange={handleCategoryChange}
            variant="outlined"
            sx={{
              width: "28%",
              ml: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#312783",
                },
                "&:hover fieldset": {
                  borderColor: "dodgerblue",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "dodgerblue",
                },
              },
              "& .MuiInputLabel-root": {
                color: "#312783",
                "&.Mui-focused": {
                  color: "dodgerblue",
                },
              },
            }}
          >
            <MenuItem value={null}>Habilidad</MenuItem>
            {categories.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box>
          {loading ? (
            <div
              style={{ textAlign: "center", color: "#312783", marginTop: 20 }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {filteredProyects.length > 0 ? (
                <List>
                  {filteredProyects.map((proyect) => (
                    <ListItem key={proyect.id}>
                      <Box style={styles.card}>
                        <Typography style={styles.title}>
                          {proyect.title}
                        </Typography>
                        <Typography style={styles.description}>
                          {proyect.description}
                        </Typography>
                        <Typography style={styles.price}>
                          Presupuesto: $ {proyect.price}
                        </Typography>
                        <Typography style={styles.skills}>
                          Habilidad: {proyect.skills}
                        </Typography>
                        <Typography style={styles.timeToDo}>
                          Fecha de entrega: {formatDate(proyect.timeToDo)}
                        </Typography>
                        <Typography style={styles.created}>
                          Fecha de publicación:{" "}
                          {proyect.createdAt.toDate().toLocaleDateString()}
                        </Typography>
                        <Button
                          variant="outlined"
                          sx={{ mt: 2 }}
                          fullWidth
                          onClick={() =>
                            navigate(`/publishProposal?proyectId=${proyect.id}`)
                          }
                        >
                          Enviar una propuesta
                        </Button>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography>No se encontraron propuestas.</Typography>
              )}
            </>
          )}
        </Box>
      </Container>
    </Container>
  );
};

export default Home;
