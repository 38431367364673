import React, { useState, useEffect } from "react";
import { Typography, AppBar, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { isLoggedIn } from "../session";
import { useNavigate } from "react-router-dom";
import Atras from "./atras";

function Header() {
  const { projectId } = useParams();
  const [proyectName, setProyectName] = useState(""); // Corrected state name

  useEffect(() => {
    const fetchProyectData = async () => {
      try {
        const db = getFirestore();
        const proyectDocRef = doc(db, "proyects", projectId);
        const proyectDocSnapshot = await getDoc(proyectDocRef);

        if (proyectDocSnapshot.exists()) {
          const proyectData = proyectDocSnapshot.data();
          const name = proyectData.title || "Producto"; // Adjusted to match your data structure
          setProyectName(name);
        } else {
          console.error("El proyecto no existe");
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    if (projectId) {
      fetchProyectData();
    }
  }, [projectId]);

  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <AppBar position="fixed">
      <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%", height: "100%" }}>
        <Atras />
        {proyectName && (
          <Typography
            variant="h6"
            sx={{ paddingLeft: "24px", paddingTop: "10px", fontSize: "24px", color: "white" }}
            gutterBottom
          >
            {proyectName}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
