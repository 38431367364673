import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import { auth, db } from "../firebase"; // Asegúrate de importar tus configuraciones de Firebase
import {
  doc,
  getDoc,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import Atras from "./atras";

const Publish = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    skills: "",
    timeToDo: new Date(), // Inicializamos con una fecha por defecto
  });
  const [publishSuccess, setPublishSuccess] = useState(false); // Estado para controlar el éxito
  const navigate = useNavigate(); // Hook para redireccionar

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleDateChange = (newDate) => {
    setFormData((prevFormData) => ({ ...prevFormData, timeToDo: newDate }));
  };

  const handlePublishProyect = async () => {
    const { uid } = auth.currentUser || {};

    const userDocRef = doc(db, "users", uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      const userName = userData.displayName;

      await addDoc(collection(db, "projects"), {
        name: userName,
        title: formData.title,
        description: formData.description,
        price: formData.price,
        skills: formData.skills,
        timeToDo: formData.timeToDo,
        createdAt: serverTimestamp(),
        uid,
      });
      setPublishSuccess(true);
    } else {
      console.error("User document does not exist");
    }
  };

  const handleRedirectHome = () => {
    navigate("/freelance/home");
  };

  const styles = {
    uploadButton: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "50px",
      backgroundColor: "#f0f0f0",
      border: "2px solid #312783",
      marginTop: "20px",
      borderRadius: "8px",
      color: "black",
    },
    progress: {
      position: "absolute",
      color: "#312783",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "dodgerblue" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Publicar Proyecto
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 14 }}>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Título del proyecto"
          type="text"
          fullWidth
          name="title"
          value={formData.title}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          id="description"
          label="Descripción del proyecto"
          type="text"
          fullWidth
          name="description"
          multiline
          rows={5}
          value={formData.description}
          onChange={handleInputChange}
          className={styles.textField}
        />
        <TextField
          margin="dense"
          id="price"
          label="Presupuesto $"
          type="text"
          fullWidth
          name="price"
          value={formData.price}
          onChange={handleInputChange}
        />
        <Typography sx={{mt:2}}>Habilidad requerida para el proyecto:</Typography>
        <Select
          label="Habilidad"
          type="text"
          name="skills"
          sx={{ mt: 1.5, marginBottom: 1 }}
          value={formData.skills}
          onChange={handleInputChange}
          displayEmpty
          fullWidth
        >
          <MenuItem value="" disabled>
            Selecciona una habilidad
          </MenuItem>
          <MenuItem value="Diseño Grafico">Diseño Gráfico</MenuItem>
          <MenuItem value="Programacion">Programación</MenuItem>
          <MenuItem value="Marketing Digital">Marketing Digital</MenuItem>
          <MenuItem value="Redaccion De Contenidos">
            Redacción de Contenidos
          </MenuItem>
          <MenuItem value="Traduccion">Traducción</MenuItem>
          <MenuItem value="Diseño Web">Diseño Web</MenuItem>
        </Select>
        <Typography sx={{mt:2}}>Fecha de entrega:</Typography>
        <DatePicker
          margin="dense"
          label="Fecha de entrega"
          format="MM/dd/yyyy"
          onChange={handleDateChange}
          value={formData.timeToDo}
        />
        {publishSuccess && (
          <Box sx={{ textAlign: "center" }}>
            <Typography>El proyecto se ha publicado correctamente.</Typography>
            <Button onClick={handleRedirectHome} color="primary">
              Ir a Proyectos
            </Button>
          </Box>
        )}
        {!publishSuccess && (
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            fullWidth
            onClick={handlePublishProyect}
            color="primary"
          >
            Publicar
          </Button>
        )}
      </Container>
    </Container>
  );
};

export default Publish;
