import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Toolbar,
  AppBar,
  TextField,
  Button,
} from "@mui/material";
import Atras from "./atras";
import { updateUser, auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

const Edit = () => {
  const [displayName, setDisplayName] = useState("");
  const [phone, setPhone] = useState("");
  const [point, setPoint] = useState("");
  const [skill, setSkill] = useState("");


  const handleDisplayNameChange = (event) => {
    setDisplayName(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handlePointChange = (event) => {
    setPoint(event.target.value);
  };

  const handleSkillChange = (event) => {
    setSkill(event.target.value);
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);


  const handleSubmit = () => {
     updateUser(user.uid, displayName, phone, point, skill);
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "110px",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "dodgerblue" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" gutterBottom>
              Editar perfil
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>

      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          width: "100%",
          textAlign: "left",
          marginTop: "140px",
        }}
      >
        <TextField
          label="Nombre"
          variant="outlined"
          value={displayName}
          onChange={handleDisplayNameChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Teléfono"
          variant="outlined"
          value={phone}
          onChange={handlePhoneChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Localidad"
          variant="outlined"
          value={point}
          onChange={handlePointChange}
          fullWidth
          margin="normal"
        />
        <TextField
        sx={{marginBottom: 3}}
          label="Habilidad"
          variant="outlined"
          value={skill}
          onChange={handleSkillChange}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Guardar cambios
        </Button>
      </Container>
    </Container>
  );
};

export default Edit;
