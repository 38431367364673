import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getAuth,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  getIdToken
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  serverTimestamp,
  runTransaction
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

require('firebase/auth');

const firebaseConfig = {
  apiKey: "AIzaSyA9xuGDdfHvfBOnox4Gq627WfiNU6akQiU",
  authDomain: "skach-931b7.firebaseapp.com",
  databaseURL: "https://skach-931b7-default-rtdb.firebaseio.com",
  projectId: "skach-931b7",
  storageBucket: "skach-931b7.appspot.com",
  messagingSenderId: "756758090081",
  appId: "1:756758090081:web:b686b9984319496bdb7520"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, db, auth, storage, serverTimestamp, runTransaction };


export const getToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      if (user) {
        getIdToken(user).then(
          (idToken) => {
            resolve(idToken);
          },
          (error) => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  });
};

export const token = async () => {
  try {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const idToken = await currentUser.getIdToken();
      return idToken;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error retrieving ID token:", error);
    return null;
  }
};

export const signInUser = (email, password) => {
  const auth = getAuth();
  return setPersistence(auth, browserLocalPersistence)
    .then(() => {
      return signInWithEmailAndPassword(auth, email, password);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      throw error;
    });
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log(
      "¡El correo electrónico para restablecer la contraseña se envió correctamente!"
    );
  } catch (error) {
    throw new Error(
      "Error al enviar el correo electrónico para restablecer la contraseña: " +
        error.message
    );
  }
};

export const createUser = async (
  email,
  password,
  displayName,
  phone,
  point,
  userType
) => {
  try {
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const user = userCredential.user;
    const uid = user.uid;

    const db = getFirestore();
    const userRef = doc(db, "users", uid);
    await setDoc(userRef, {
      uid: uid,
      email: email,
      displayName: displayName,
      phone: phone,
      point: point,
      userType: userType,
      profileImageUrl: "https://firebasestorage.googleapis.com/v0/b/notiline-vtoisn.appspot.com/o/images%2Fuser.png?alt=media&token=2e959a3e-a008-4ab0-820f-bd8cec1dd268",
    });

    return userCredential;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (uid, displayName, phone, point) => {
  try {
    const db = getFirestore(app);
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, {
      displayName: displayName,
      phone: phone,
      point: point,
    });
    const userData = await getDoc(userRef);
    if (userData.exists()) {
      return { uid, ...userData.data() };
    } else {
      throw new Error("El usuario no existe");
    }
  } catch (error) {
    throw error;
  }
};

export const uploadImage = async (file) => {
  try {
    const randomId = Math.random().toString(36).substr(2, 9); // Genera un identificador único
    const storageRef = ref(storage, `images/${randomId}-${file.name}`); // Crea una referencia con el identificador aleatorio
    await uploadBytes(storageRef, file); // Sube el archivo
    const downloadURL = await getDownloadURL(storageRef); // Obtiene la URL de descarga del archivo
    return downloadURL;
  } catch (error) {
    throw error;
  }
};

export const updateUserProfile = async (uid, profileData) => {
  try {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, profileData);
    console.log("Perfil del usuario actualizado correctamente.");
  } catch (error) {
    console.error("Error al actualizar el perfil del usuario:", error);
    throw error;
  }
};